import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Image, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const ImageGallery = ({ media, loadMore }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const handlePrevious = useCallback(() => {
    setSelectedImageIndex(
      selectedImageIndex > 0 ? selectedImageIndex - 1 : media.length - 1
    );
  }, [selectedImageIndex, media.length]);

  const handleNext = useCallback(() => {
    if (selectedImageIndex < media.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    } else {
      loadMore && loadMore();
    }
  }, [selectedImageIndex, media.length, loadMore]);

  const isVideo = (item) => {
    if (item && item.source && typeof item.source === "string") {
      try {
        const url = new URL(item.source);
        const pathname = url.pathname;
        return pathname.endsWith(".mp4") || pathname.endsWith(".webm");
      } catch (error) {
        console.error("Invalid URL", error);
      }
    }
    return false;
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        handlePrevious();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePrevious, handleNext]);
  return (
    <Container>
      <Row>
        {media.map((image, index) => (
          <Col
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={`${image.src}-${index}`}
            onClick={() => setSelectedImageIndex(index)}
          >
            <Image
              className='m-1'
              src={image.src}
              alt={image.description}
              thumbnail
            />
          </Col>
        ))}
      </Row>
      <Modal
        show={selectedImageIndex !== null}
        onHide={() => setSelectedImageIndex(null)}
        centered
      >
        <Modal.Body className='max-h-[90vh] text-center'>
          {selectedImageIndex !== null && (
            <>
              {media[selectedImageIndex]?.message && (
                <div className='image-gallery-message mb-3'>
                  <p className='text-muted'>
                    {media[selectedImageIndex].message}
                  </p>
                </div>
              )}
              <h5>
                <b>{media[selectedImageIndex]?.title || ""}</b>
              </h5>

              {isVideo(media[selectedImageIndex]) ? (
                <video
                  controls
                  className='w-100'
                  src={media[selectedImageIndex]?.source || ""}
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <Image
                  src={media[selectedImageIndex]?.src || ""}
                  alt={media[selectedImageIndex]?.description || ""}
                  fluid
                />
              )}
              <p>{media[selectedImageIndex]?.description || ""}</p>
              <div className='d-flex justify-content-between mt-3'>
                <button className='btn btn-dark' onClick={handlePrevious}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <span style={{ color: "black" }}>
                  {selectedImageIndex + 1}/{media.length}
                </span>
                <button className='btn btn-dark' onClick={handleNext}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ImageGallery;
