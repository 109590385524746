import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ScholarshipCard from "../components/ScholarshipCard";
import { diasporaData } from "../utils/utils";
import diasporaImage from "../files/footer-logo-en-diaspora.png";
import "../styles/ScholarshipPage.css";
import ImageWithSpinner from "../components/ImageWithSpinner";

import filler2 from "../files/diaspora_new_1.jpg";
//import filler3 from "../files/diaspora_filler2.jpg";
import filler4 from "../files/diaspora_filler3.jpg";
//import filler5 from "../files/diaspora_filler4.jpg";
const DiasporaScholarshipPage = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className='scholarship-page'>
      <Row className='my-2'>
        <Col lg={12}>
          <h1 className='text-center'>{t("scholarship2_title")}</h1>
        </Col>
      </Row>
      <Row className='mb-1 scholarship-banner justify-content-center align-items-center'>
        <Col xs={12} className='text-center'>
          <ImageWithSpinner
            src={diasporaImage}
            alt='study-in-hungary-with-scholarship-free-european-education-university-degree'
            className='img-fluid text-center text-justify'
            style={{ height: "15em" }}
          />
          <p className='text-justify my-1'>
            <h3>{t("scholarship2_desc")}</h3>
          </p>
        </Col>
      </Row>
      {/*
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler5}
              className='img-fluid image-frame'
              alt='efraim-kishon-hungarian-diaspora-in-israel-study-free-in-hungary-scholarship'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler3}
              className='img-fluid image-frame'
              alt='israel-study-free-in-europe-scholarship-free-tuition'
            />
          </Col>
        </Row>
  </Container>*/}

      <Row className='justify-content-center'>
        {diasporaData.map((card, index) => (
          <ScholarshipCard key={index} {...card} />
        ))}
      </Row>
      <Row className='my-2 mt-4'>
        <Col className='text-center'>
          <Button
            variant='primary'
            href='https://diasporascholarship.hu/en'
            target='_blank'
          >
            {t("learn_more_apply")}
          </Button>
        </Col>
      </Row>
      <Row className='my-2 '>
        <Col lg={12}>
          <Card>
            <Card.Header as='h2' className='text-center'>
              {t("motivation_letter_guidelines_title")}
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul>
                  <li>{t("motivation_letter_guideline1")}</li>
                  <li>{t("motivation_letter_guideline2")}</li>
                  <li>{t("motivation_letter_guideline3")}</li>
                  <li>{t("motivation_letter_guideline4")}</li>
                  <li>{t("motivation_letter_guideline5")}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='my-5 justify-content-md-center'>
        <Col md={8} lg={6}>
          <div className='text-box'>
            <h4 className='text-center'>{t("scholarship2_letter_title")}</h4>
            <p className='text-description'>{t("scholarship2_letter_desc")}</p>
            <div className='contact-info'>
              <a className='phone-link' href='tel:+972542252112'>
                <i className='fas fa-phone'></i> +972 54 225 2112
              </a>
              <a className='email-link' href='mailto:kishoneducenter@gmail.com'>
                <i className='fas fa-envelope'></i> kishoneducenter@gmail.com
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Container className='my-5'>
        <Row>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler4}
              className='img-fluid image-frame'
              alt='-hungarian-diaspora-free-tuition-education-scholarship-study-in-europe'
            />
          </Col>
          <Col md={6} sm={12}>
            <ImageWithSpinner
              src={filler2}
              className='img-fluid image-frame'
              alt='hungarian-scholarship-scholarship-for-BA-Msc-Phd-medicine-scholarchip-europe'
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default DiasporaScholarshipPage;
