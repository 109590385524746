import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import {
  RightAlignedDropdown,
  StyledReactCountryFlag,
} from "../styles/LanguageSelectorStyles";

const LanguageSelector = ({ isNavExpanded }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedFlag, setSelectedFlag] = useState("GB");

  // Set the initial selected flag based on the current language
  useEffect(() => {
    const currentLang = i18n.language;
    switch (currentLang) {
      case "he":
        setSelectedFlag("IL");
        break;
      case "hu":
        setSelectedFlag("HU");
        break;
      case "en":
      default:
        setSelectedFlag("GB");
        break;
    }
  }, [i18n.language]);

  const changeLanguage = (language, flag) => {
    i18n.changeLanguage(language);
    setSelectedFlag(flag);

    const currentPath = window.location.pathname;
    const newPathParts = currentPath.split("/");
    newPathParts[1] = language; // Update the language part in the URL
    const newPath = newPathParts.join("/");

    navigate(newPath);
  };

  return (
    !isNavExpanded && (
      <RightAlignedDropdown>
        <div className='d-flex align-items-center'>
          <span className='mr-2 text-white'>{i18n.language.toUpperCase()}</span>
          <Dropdown.Toggle
            as={StyledReactCountryFlag}
            countryCode={selectedFlag}
            svg
            id='dropdown-basic'
          />
        </div>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => changeLanguage("en", "GB")}>
            <ReactCountryFlag countryCode='GB' svg /> English
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage("he", "IL")}>
            <ReactCountryFlag countryCode='IL' svg /> עברית
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage("hu", "HU")}>
            <ReactCountryFlag countryCode='HU' svg /> Magyar
          </Dropdown.Item>
        </Dropdown.Menu>
      </RightAlignedDropdown>
    )
  );
};

export default LanguageSelector;
