import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

const ImageWithSpinner = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div className={className + " image-container"}>
      {isLoading && (
        <Spinner animation='border' role='status' className='image-spinner'>
          <span className='sr-only'></span>
        </Spinner>
      )}
      <img
        src={src}
        alt={alt}
        className={`img-fluid ${isLoading ? "hidden" : ""}`}
        onLoad={handleImageLoaded}
      />
    </div>
  );
};

export default ImageWithSpinner;
